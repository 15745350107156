/* External Imports */
@import "tailwindcss/base";
@import "tailwindcss/components";
@import "tailwindcss/utilities";

/* Pincites logo fill in light and dark mode */
path#pincites_logo {
  fill: url(#gradient_blue);
}
@media (prefers-color-scheme: dark) {
  path#pincites_logo {
    fill: url(#gradient_white);
  }
}

.markdown {
  line-height: 1.5;
}

.markdown li {
  margin-bottom: 0.5rem;
}

.markdown ul {
  list-style-type: disc;
  margin-left: 2em;
  margin-top: 0;
  margin-bottom: 1rem;
}

.markdown ol {
  list-style-type: decimal;
  padding-left: 2em;
  margin-bottom: 1rem;
}

.markdown p {
  margin-bottom: 1rem;
}

.markdown h1,
.markdown h2,
.markdown h3,
.markdown h4 {
  font-weight: 600;
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  line-height: 1.25;
}

.markdown h1,
.markdown h2 {
  padding-bottom: 0.3em;
  border-bottom: 1px solid #3d444db3;
}

.markdown h1 {
  font-size: 2em;
}

.markdown h2 {
  font-size: 1.5em;
}

.markdown h3 {
  font-size: 1.25em;
}

.markdown h4 {
  font-size: 1em;
}

.markdown blockquote {
  border-left: 0.25em solid #7e7e7e;
  color: #9198a1;
  font-style: italic;
  padding: 0 1em;
}

.markdown img {
  max-width: 600px;
  height: auto;
  max-height: 400px;
  margin: 1rem 0;
  display: flex;
  justify-self: center;
  align-self: center;
}

/* Links */
.markdown a {
  color: #115ea3; /* From Fluent docs: https://react.fluentui.dev/?path=/docs/theme-colors--docs */
  text-decoration: underline;
  text-underline-offset: 0.2em;
}

@media (prefers-color-scheme: dark) {
  .markdown a {
    color: #479ef5; /* From Fluent docs: https://react.fluentui.dev/?path=/docs/theme-colors--docs */
  }
}
